*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ReworkDisplay-Semibold, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.z-\[2000\] {
  z-index: 2000;
}

.z-50 {
  z-index: 50;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-\[10\/8\] {
  aspect-ratio: 10 / 8;
}

.h-screen {
  height: 100vh;
}

.h-auto {
  height: auto;
}

.min-h-screen {
  min-height: 100vh;
}

.w-8 {
  width: 2rem;
}

.w-full {
  width: 100%;
}

.w-\[180px\] {
  width: 180px;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[100px\] {
  width: 100px;
}

.w-\[170px\] {
  width: 170px;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[70px\] {
  width: 70px;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-\[32\.5em\] {
  max-width: 32.5em;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-\[34em\] {
  max-width: 34em;
}

.max-w-\[29\.5em\] {
  max-width: 29.5em;
}

.max-w-\[25\.5em\] {
  max-width: 25.5em;
}

.max-w-\[26em\] {
  max-width: 26em;
}

.grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

.resize {
  resize: both;
}

.flex-col {
  flex-direction: column;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse) );
}

.space-y-\[20px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(20px * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(20px * var(--tw-space-y-reverse) );
}

.-space-y-\[30px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-30px * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(-30px * var(--tw-space-y-reverse) );
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(2rem * var(--tw-space-y-reverse) );
}

.space-y-\[30px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(30px * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(30px * var(--tw-space-y-reverse) );
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(3rem * var(--tw-space-y-reverse) );
}

.-space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse) );
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-2xl {
  border-radius: 1rem;
}

.border {
  border-width: 1px;
}

.bg-\[\#fff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.bg-rclime {
  --tw-bg-opacity: 1;
  background-color: rgb(204 255 0 / var(--tw-bg-opacity) );
}

.bg-rcgrey {
  --tw-bg-opacity: 1;
  background-color: rgb(249 240 235 / var(--tw-bg-opacity) );
}

.bg-\[whitesmoke\] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity) );
}

.bg-rcgreen {
  --tw-bg-opacity: 1;
  background-color: rgb(0 49 34 / var(--tw-bg-opacity) );
}

.bg-rcyellow {
  --tw-bg-opacity: 1;
  background-color: rgb(228 255 122 / var(--tw-bg-opacity) );
}

.bg-cover {
  background-size: cover;
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.bg-top {
  background-position: top;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-rclime {
  fill: #cf0;
}

.p-5 {
  padding: 1.25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pb-\[40px\] {
  padding-bottom: 40px;
}

.pb-0 {
  padding-bottom: 0;
}

.text-center {
  text-align: center;
}

.font-serif {
  font-family: Tobias-Regular, serif;
}

.font-sans {
  font-family: ReworkDisplay-Semibold, sans-serif;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-\[16px\] {
  font-size: 16px;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.not-italic {
  font-style: normal;
}

.leading-tight {
  line-height: 1.25;
}

.leading-\[1\.1\] {
  line-height: 1.1;
}

.leading-\[1\.3\] {
  line-height: 1.3;
}

.tracking-\[0\.02em\] {
  letter-spacing: .02em;
}

.text-rclime {
  --tw-text-opacity: 1;
  color: rgb(204 255 0 / var(--tw-text-opacity) );
}

.text-rcgreen {
  --tw-text-opacity: 1;
  color: rgb(0 49 34 / var(--tw-text-opacity) );
}

@font-face {
  font-family: ReworkDisplay-Semibold;
  src: url("ReworkDisplay-Semibold.27744d63.woff");
}

@font-face {
  font-family: Tobias-Regular;
  src: url("Tobias-Regular.c20fed38.woff");
}

body {
  overscroll-behavior: none;
  overflow-x: hidden;
}

.chevron:hover {
  cursor: pointer;
}

.squares {
  background-image: linear-gradient(#cf0 0% 35%, #fff 35% 100%);
  overflow: hidden;
}

.wrapper {
  max-width: 1550px;
  margin: 0 auto;
}

.defTitle {
  letter-spacing: 0;
  font-family: ReworkDisplay-Semibold, sans-serif;
  font-size: 24px;
  line-height: 1.1;
}

@media (min-width: 1024px) {
  .defTitle {
    font-size: 28px;
  }
}

@media (min-width: 1280px) {
  .defTitle {
    font-size: 38px;
  }
}

.defBody {
  font-family: Tobias-Regular, serif;
  font-size: 20px;
  line-height: 1.3;
}

@media (min-width: 1024px) {
  .defBody {
    font-size: 24px;
  }
}

@media (min-width: 1280px) {
  .defBody {
    font-size: 28px;
  }
}

@media (min-width: 640px) {
  .sm\:block {
    display: block;
  }

  .sm\:hidden {
    display: none;
  }
}

@media (min-width: 768px) {
  .md\:block {
    display: block;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:aspect-\[16\/9\] {
    aspect-ratio: 16 / 9;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-\[21px\] {
    font-size: 21px;
  }
}

@media (min-width: 1024px) {
  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-\[190px\] {
    width: 190px;
  }

  .lg\:w-\[175px\] {
    width: 175px;
  }

  .lg\:max-w-4xl {
    max-width: 56rem;
  }

  .lg\:max-w-3xl {
    max-width: 48rem;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse) ) );
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse) );
  }

  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse) ) );
    margin-bottom: calc(0px * var(--tw-space-y-reverse) );
  }

  .lg\:space-y-\[75px\] > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(75px * calc(1 - var(--tw-space-y-reverse) ) );
    margin-bottom: calc(75px * var(--tw-space-y-reverse) );
  }

  .lg\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse) );
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse) ) );
  }

  .lg\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse) ) );
    margin-bottom: calc(2rem * var(--tw-space-y-reverse) );
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:p-12 {
    padding: 3rem;
  }

  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:text-right {
    text-align: right;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-\[22px\] {
    font-size: 22px;
  }
}

@media (min-width: 1280px) {
  .xl\:block {
    display: block;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:w-10 {
    width: 2.5rem;
  }

  .xl\:w-\[260px\] {
    width: 260px;
  }

  .xl\:w-\[240px\] {
    width: 240px;
  }

  .xl\:w-\[70px\] {
    width: 70px;
  }

  .xl\:w-\[110px\] {
    width: 110px;
  }

  .xl\:max-w-lg {
    max-width: 32rem;
  }

  .xl\:max-w-4xl {
    max-width: 56rem;
  }

  .xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

/*# sourceMappingURL=index.547e1a41.css.map */
