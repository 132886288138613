@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family:'ReworkDisplay-Semibold';
    src: url(./assets/fonts/ReworkDisplay-Semibold.woff);
}

@font-face {
    font-family:'Tobias-Regular';
    src: url(./assets/fonts/Tobias-Regular.woff);
}



body {
    overscroll-behavior:none;
    overflow-x: hidden;
}


.chevron:hover{
    cursor: pointer;
}


.squares {

    background-image:linear-gradient(to bottom, 
    #CCFF00 0% 35%,
    #FFFFFF 35% 100%
  );

  overflow:hidden;

}


.wrapper {

    max-width:1550px;
    margin:0 auto;

}




.defTitle {

    @apply font-sans text-[24px] lg:text-[28px] xl:text-[38px] leading-[1.1] tracking-[0];
}


.defBody {

@apply font-serif text-[20px] lg:text-[24px] xl:text-[28px] leading-[1.3];

}